import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { relayStylePagination } from '@apollo/client/utilities';
import { inMemoryCacheTypePolicies } from '@volvo/vce-package-site-uikit';
import { toast } from '@volvo/vce-uikit';

// More cache config: https://www.apollographql.com/docs/react/caching/cache-configuration/
enum ApolloClientName {
  'super_graph' = 'super_graph',
  'emob' = 'emob',
}

let client: ApolloClient<NormalizedCacheObject> | null;
export const getApolloClient = (getToken: () => Promise<string | void>) => {
  if (!client) {
    client = createApolloClient(getToken);
  }
  return client;
};

const createApolloClient = (getToken: () => Promise<string | void>) => {
  const cache = new InMemoryCache({
    typePolicies: {
      SiteDeviceEventQueries: {
        fields: {
          siteDeviceEvents: relayStylePagination(),
          siteDeviceZoneEvents: relayStylePagination(),
        },
      },
      SiteLoadTickets: {
        fields: {
          siteLoadTickets: relayStylePagination(),
          sourceTicket: {
            merge: true,
          },
          weight: {
            merge: true,
          },
        },
      },
      Query: {
        fields: {
          siteMachines: {
            merge: true,
          },
          materialVariants: {
            merge: true,
          },
          zones: {
            merge: true,
          },
          zoneMaterialBalance: {
            merge: true,
          },
          emobAssetTypes: {
            merge: true,
          },
          machineTypePermissions: {
            merge: true,
          },
          materialFlows: {
            merge: true,
          },
          ...inMemoryCacheTypePolicies.Query.fields,
        },
      },
    },
  });
  const authMiddleware = setContext(async (_, { headers }) => {
    const token = await getToken();
    const clientHeaders = {
      'x-graphql-client-name': process.env.APP_NAME,
      'x-graphql-client-version': process.env.BUILD_NUMBER,
    };
    return {
      headers: {
        ...headers,
        ...clientHeaders,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  // More regarding links: https://www.apollographql.com/docs/react/api/link/introduction/
  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        console.error(`[GraphQL error]: Message: ${error.message},`, error);
        if (error.extensions?.code === 'UNAUTHENTICATED') {
          localStorage.removeItem('token');
          window.location.href = '/login';
        } else {
          toast.error(graphQLErrors.map((e) => e.message).join(', '));
        }
      });
    }
    if (networkError) console.error(`[Network error]: ${networkError.message}`, networkError);
    forward(operation);
  });

  const httpLinkSuperGraph = new HttpLink({
    uri: import.meta.env.VITE_API_GRAPHQL_ENDPOINT_SUPER_GRAPH,
  });

  const httpLinkEmob = new HttpLink({
    uri: import.meta.env.VITE_API_GRAPHQL_ENDPOINT_EMOB,
  });

  const retryLink = new RetryLink();

  const extensionsLink = new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
      if (response.data) {
        response.data.apolloExtensions = response.extensions;
      }
      return response;
    }),
  );

  return new ApolloClient({
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
    headers: {
      'client-name': 'SiteMap',
      'client-version': '1.0.0', // TODO config
    },
    link: ApolloLink.from([
      retryLink,
      errorLink,
      authMiddleware,
      extensionsLink,
      ApolloLink.split(
        (operation) => operation.getContext().clientName === ApolloClientName.emob,
        httpLinkEmob,
        httpLinkSuperGraph,
      ),
    ]),
  });
};
